import React from 'react'
import NavCardList from '../../Partials/Layouts/NavCardList/NavCardList'
import NavCard from '../../Partials/Layouts/NavCardList/NavCard/NavCard'
import {  RiImageLine } from 'react-icons/ri'

function Dashboard() {
  return (
    <NavCardList numOfCards={'six'}>
      <NavCard cardName={'Hero Sliders'} navCardLink={'/heroSliders'} ><RiImageLine /></NavCard>

    </NavCardList>
  )
}

export default Dashboard