import { useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import { SLIDERS_API } from "../../../Utilities/APIs"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"
import { useEffect } from "react"


function UpdateHeroSlider({

    targetID, employee, setShowUpdateForm, setShowModal, triggerFetch

}) {

    const [image, setImage] = useState('')
    const [image1, setImage1] = useState('')
    const [precedence, setPrecedence] = useState('')

    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axios.get(SLIDERS_API + targetID)
            setImage(data.image);
            setImage1(data.image1);
            setPrecedence(data.precedence);
        }
        fetchAndSetSliderInfo()
    }, [targetID])

    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('image1', image1)
        itemData.append('precedence', precedence)

        const response = await axios.patch(SLIDERS_API + targetID, itemData)

        if (response) {

            setShowUpdateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowUpdateImage
            >
                Upload Card Image
            </ImageInput>
            <ImageInput
                fieldId='2'
                state={image1}
                setState={setImage1}
                allowUpdateImage
            >
                Upload Card Image1
            </ImageInput>
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />


            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Update Hero Slider' />
        </Form>
    )
}

export default UpdateHeroSlider