import { useState, useEffect } from "react"
import axios from "axios"
import { IMAGE_URL, SLIDERS_API } from "../../../Utilities/APIs"

function ViewHeroSlider({

    //common props
    targetID, employee

}) {

    const [slider, setSlider] = useState(null)

    useEffect(() => {

        async function fetchAndSetSliderInfo() {

            const { data } = await axios.get(SLIDERS_API + targetID)
            setSlider(data);
        }
        fetchAndSetSliderInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {slider &&
                <>
                   
                    <h1>Image</h1>
                    <img src={IMAGE_URL + slider.image} alt="" />
                    <h1>Image1</h1>
                    <img src={IMAGE_URL + slider.image1} alt="" />
                    <h1>Precedence</h1>
                    <p>{slider.precedence}</p>

                </>
            }
        </div>
    )
}

export default ViewHeroSlider