import { useState, useEffect } from "react"
import axios from "axios"
import { BLOGS_API, IMAGE_URL } from "../../../Utilities/APIs"

function ViewBlog({

    //common props
    targetID, employee

}) {

    const [blogInfo, setBlogInfo] = useState(null)

    useEffect(() => {

        async function fetchAndSetBlogInfo() {

            const { data } = await axios.get(BLOGS_API + targetID)
            setBlogInfo(data);
        }
        fetchAndSetBlogInfo()
    }, [targetID])

    return (
        <div className="crud_view_content">

            {blogInfo &&
                <>
                    <h1>Name</h1>
                    <p>{blogInfo.name}</p>
                    <h1>Image</h1>
                    <img src={IMAGE_URL + blogInfo.image} alt="" />
                    <h1>Description</h1>
                    <div
                        dangerouslySetInnerHTML={{ __html: blogInfo.description }}
                    />
                    <h1>Short Description</h1>
                    <p>{blogInfo.shortDescription}</p>

                </>
            }
        </div>
    )
}

export default ViewBlog