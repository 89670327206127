import { useState } from "react"
import axios from "axios"
import Form from "../../Partials/Layouts/Forms/Form"
import {  SLIDERS_API } from "../../../Utilities/APIs"
import ImageInput from "../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput"
import FormSubmitButton from "../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton"
import NumberInput from "../../Partials/Layouts/Forms/FormInputs/NumberInput/NumberInput"


function CreateHeroSlider({

    employee, setShowCreateForm, setShowModal, triggerFetch

}) {

    const [image, setImage] = useState('')
    const [image1, setImage1] = useState('')
    const [precedence, setPrecedence] = useState('')


    async function handleSubmit(e) {

        e.preventDefault()

        // const config = {
        //     headers: {
        //         Authorization: `Bearer ${employee.token}`
        //     }
        // }
        const itemData = new FormData()

        itemData.append('image', image)
        itemData.append('image1', image1)
        itemData.append('precedence', precedence)

        const response = await axios.post(SLIDERS_API, itemData)

        if (response) {

            setShowCreateForm(false);
            setShowModal(false);
            triggerFetch()
        }

    }

    return (
        <Form onSubmit={handleSubmit} hasImage>

            <ImageInput
                fieldId='1'
                state={image}
                setState={setImage}
                allowCreateImage
            >
                Upload Card Image
            </ImageInput>
            <ImageInput
                fieldId='2'
                state={image1}
                setState={setImage1}
                allowCreateImage
            >
                Upload Card Image1
            </ImageInput>
            <NumberInput
                label={`Precedence`}
                value={precedence}
                placeholder={`Enter Precedence`}
                setState={setPrecedence}
            />


            {/* <ImageInput fieldId='1' state={image} setState={setImage} allowCreateImage>Upload Card Image</ImageInput> */}

            <FormSubmitButton text='Create Hero Slider' />
        </Form>
    )
}

export default CreateHeroSlider