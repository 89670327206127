import React from 'react'
import BLTCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/BLTCRUD/BLTCRUD'
import { SOCIAL_LINKS_API } from '../../../Utilities/APIs'

function SocialLinks() {
    return (
        <BLTCRUD
            // common props
            api={SOCIAL_LINKS_API}
            screenTopicSingular='Social Link'
            screenTopicPlural='Social Links'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete

            // field props
            booleanDBField='visibility'
            booleanName='Visibility'

            // Text field props
            textDBField='platform'
            textName='Platform'

        />
    )
}

export default SocialLinks