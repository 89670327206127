import { RiFolderFill } from "react-icons/ri"

function MediaFolder({ id, screen, setScreen, name, setSelectedCollection }) {

    function handleClick() {
        if (screen === 'initial') {
            if (name === 'Blogs') {
                setScreen('blog')
            }
         
        }
        if (screen !== 'initial') {
            setScreen('mediaList')
            setSelectedCollection(id)
        }
    }
    return (
        <div
            className="folder"
            onClick={handleClick}
        >
            <RiFolderFill />
            <span>{name}</span>
        </div>
    )
}

export default MediaFolder