import React from 'react'
import DIPCRUD from '../../Partials/Layouts/CRUDs/CRUDLayouts/DIPCRUD/DIPCRUD'
import { ABOUTS_API } from '../../../Utilities/APIs'

function About() {
    return (
        <DIPCRUD
            // common props
            api={ABOUTS_API}
            screenTopicSingular='About'
            screenTopicPlural='Abouts'


            // crud header props
            showTotalInHeading


            // crud props
            allowCreate
            allowUpdate
            allowDelete

            // field props
            maxPrecedence={5}
        />
    )
}

export default About