import { useState, useEffect } from "react"
import axios from "axios"
import { CONTACTS_API } from "../../../Utilities/APIs"


function ViewContactInfo({

    //common props
    targetID, employee

}) {

    const [contactInfo, setContactInfo] = useState(null)
    console.log(contactInfo)
    useEffect(() => {

        async function fetchAndSetInfo() {
            const { data } = await axios.get(CONTACTS_API + 'getSingleContacts/' + targetID)
            setContactInfo(data);
        }
        fetchAndSetInfo()
    }, [targetID])

    return (

        <div className="crud_view_content">

            {contactInfo &&
                <>
                    <h1>Name</h1>
                    <p>{contactInfo.name}</p>
                    <h1>Email</h1>
                    <p>{contactInfo.email}</p>
                    <h1>Regarding</h1>
                    <p>{contactInfo.regarding}</p>
                    <h1>Phone</h1>
                    <p>{contactInfo.phone}</p>
                    <h1>Message</h1>
                    <p>{contactInfo.message}</p>
                </>
            }
        </div>
    )
}

export default ViewContactInfo